.product {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  background-color: var(--cardBackgroundColorLight);
  color: white;
  border: var(--borderLight);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 10px 15px 25px;
  z-index: 1;
  transition: 0.2s ease-in-out;
}

.product:hover{
  border: var(--borderOrange);
}

.product__container {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 20px;
  width: 100%;
  height: 90%;
  max-height: 400px;
  min-width: calc(150px + 4vw);
  /* background-color: rgba(43, 61, 226, 0.308); */
}

.product__container > a > img {
  width: 100%;
  height: 20vh;
  max-width: 150px;
  max-height: 150px;
  object-fit: contain;
  margin-top: 25px;
  margin-bottom: 15px;
  cursor: pointer;
  /* background-color: darkblue; */
}

.product__info {
  min-height: 75px;
  margin-bottom: 15px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: darkgray; */
}

.product__info:hover {
  cursor: pointer;
}

.product__details{
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.button_animation{
  animation: button_animation 300ms ease-in-out;
}

@keyframes button_animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 800px) {
  .product__container {
    min-width: 80px;
    max-height: 270px;
    font-size: 8px;
    margin-top: 10px;
  }
  .product__container > a > img {
    max-height: 90px;
    padding: 0px 5px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .product__details{
      flex-direction: column;
      align-items: flex-start;
  }

  .product__info{
    justify-content: space-around;
    margin-bottom: 0;
  }
}
