.product__thumbnails{
    display: flex;
    flex-direction: column;
    margin: 20px 2vw 0px 10px;
}

.product__thumbnails > * + * {
    margin-top: 10px;
}


@media only screen and (max-width: 1000px){

    .product__thumbnails{
        margin-right: 25px;
    }
}