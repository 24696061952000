body {
  background-color: rgba(23, 26, 31, 0.92);
}
.container {
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  height: 100%;
}

.card {
  display: flex;
  width: 90%;
  max-width: 450px;
  height: 100%;
  min-width: 300px;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 10px 16px 0px 16px;
  /* background-color: blue; */
}

.icon{
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}