.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  color: white;
  position: fixed;
  top: 13%;
  left: 5%;
  width: 80%;
  max-width: 550px;
  background-color: var(--myLighterBlue);
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}

.modal-spinner{
  position: fixed;
  top: 47%;
  left: 47%;
  z-index: 30;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@media only screen and (max-width: 800px) {
  .modal {
    top: 5%;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
