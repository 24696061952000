* {
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: #171a1f;
  max-height: 100%;
  /* background-color: #282c34; */
  /* background-color: white; */
  color: white;
}

a {
  color: #42a6ff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: var(--lighterOrange);
}

:root {
  --myDarkBlue: rgb(19, 25, 33);
  --myLighterBlue: rgb(35, 47, 62);

  --cardBackgroundColor: rgb(36, 36, 38);
  --cardBackgroundColorLight: rgb(60, 61, 65);

  --myOrange: orange;
  --lighterOrange: rgb(255, 190, 105);

  --priceColor: #d83911; /* #B12704 */

  --borderColor: rgb(70, 70, 70);
  --borderColorLight: rgb(92, 92, 92);
  --border: 1px solid var(--borderColor);
  --borderLight: 1px solid var(--borderColorLight);
  --borderOrange: 1px solid var(--myOrange);
  --borderOrangeLight: 1px solid var(--lighterOrange);
}

/* Scrollbar */
*::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.75rem;
}

*::-webkit-scrollbar-track {
  border-radius: 100px;
}

*::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--lighterOrange);
}

::-webkit-scrollbar-thumb:active {
  background: var(--myOrange);
}

.flex_fullWidth_center {
  display: flex;
  width: 100%;
  justify-content: center;
}
