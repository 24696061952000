:root {
  --speed: 800ms; 
}

.form-primary-enter {
  transform: translateX(-100%);
  position: relative;

}
.form-primary-enter-active {
  transform: translateX(0%);

  transition: all var(--speed) ease;
}
.form-primary-exit {
  position: absolute;
}
.form-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}


.form-secondary-enter {
  transform: translateX(110%);
}
.form-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.form-secondary-exit {

}
.form-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

