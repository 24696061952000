.main {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  column-gap: 5px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.text {
  display: flex;
  justify-content: center;
}

.outer_bar {
  display: flex;
  width: 100%;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #e3e6e6;
  background-color: #f0f2f2;
  margin: 5px 1px;
}

.inner_bar {
  display: flex;
  width: 70%;
  border-radius: 4px;
  background-color: #ffa41c;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bar_hover {
  border: 1px solid var(--myOrange);
  box-shadow: inset -2px 0 0 -1px #f5961d, inset 0 0 0 1px #de7921;
  background-color: rgba(255, 164, 28, .25);
  margin: 4px 0px;
}

.text_hover{
  color: #de7921;
  text-decoration: underline;
}