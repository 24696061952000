.cart__main {
  display: flex;
  width: 100%;
  height: 100%;
  color: white;
  padding-bottom: 100px;
  /* background-color: rgba(0, 0, 255, 0.096); */
}

.cart__left {
  flex: 0.9;
  padding: 50px 20px 20px 20px;
  /* background-color: tomato; */
}

.cart__container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 20px;
  background-color: var(--cardBackgroundColor);
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.cart__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: darkgray;
  border-bottom: 1px solid gray;
}

.cart__details {
  width: 100%;
  padding: 20px 15px 15px 15px;
}

.cart__right {
  flex: 0.1;
  padding: 20px 20px 20px 10px;
  /* background-color: violet; */
}

.checkout__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 300px;
  height: 125px;
  padding: 20px 0px 0px 20px;
  background-color: var(--cardBackgroundColor);
  /* background-color: rgb(34, 35, 37); */
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.checkout__button {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
}

.item-animation-enter {
  opacity: 0;
}
.item-animation-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}
.item-animation-exit {
  opacity: 1;
}
.item-animation-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

@media only screen and (max-width: 800px) {
  .cart__main {
    padding: 20px 0px;
    flex-direction: column;
    align-items: center;
  }

  .cart__left {
    padding: 0;
    width: 95%;
  }

  .cart__details {
    /* background-color: rgba(255, 38, 38, 0.11); */
    padding: 0;
    margin: 0;
    margin: 15px;
  }

  .cart__right {
    padding: 0;
    padding: 20px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 95%;
  }
}
