.department {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  background-color: var(--cardBackgroundColor);
  border: var(--border);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  color: white;
  z-index: 1;
  padding: 0px 10px;
  border-radius: 15px;
  transition: 0.25s ease-in-out;
}

.department:hover {
  transform: scale(0.99);
  border: var(--borderOrangeLight);
}

.department__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
  width: 90%;
  height: 90%;
  max-height: 400px;
  min-width: 200px;
}

.department__info {
  min-height: 50px;
  max-height: 100%;
  margin-bottom: 15px;
  /* background-color: blue; */
  width: 100%;
  overflow: hidden;
}

.department__info > img {
  height: 100%;
  object-fit: fill;
  margin-bottom: 15px;
  transition: 0.2s ease-in-out;
}

.department__info > img:hover {
  transform: scale(1.05);
}

@media only screen and (max-width: 800px) {
  .department {
    width: 100%;
    font-size: 0.4rem;
    height: min(200px, 27vh);
    padding: 0;
  }

  .department__container {
    min-width: 100px;
    width: 85%;
    margin-top: 10px;
  }

  .department__info {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
  }
}
