.container {
  display: flex;
  align-items: center;
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.5rem 0.35rem;
  margin: 1px;
}

.main:hover {
  margin: 0;
  cursor: pointer;
  border: 1px solid white;
  /* border: 1px solid rgb(85, 92, 95); */
}

.top_text {
  font-size: 13px;
  color: rgb(232, 230, 227);
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 14px;
  height: 14px px;
  font-weight: 400;
}

.bottom_text {
  font-size: 16px;
  line-height: 15px;
  font-weight: 700;
}

.bottom_text > * + * {
  margin-left: 3px;
}

.dropdown {
  position: absolute;
  top: calc(6vh + 2px);
  width: 150px;
  padding: 1rem;
  overflow: hidden;
  border-radius: 5px;
  /* background-color: #3c3d41; */
  /* background-color: #131921; */
  box-shadow: 0 10px 12px rgba(0, 0, 0, 0.65);
  background-color: rgba(40, 40, 40, 1);
}
@supports ((-webkit-backdrop-filter: blur(30px)) or (backdrop-filter: blur(30px))) {
  .dropdown {
    background-color: rgba(40, 40, 40, 0.35);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
  }
}

.dropdown_content {
  display: flex;
  flex-direction: column;
}

.dropdown_items {
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  line-height: 1.5rem;
}
