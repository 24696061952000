.categories__main {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--myLighterBlue);
  max-width: 100vw;
  /* min-width: 1150px; */
  height: 10px;
  min-height: 30px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}

.categories__content {
  display: grid;
  gap: 2px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.close__icon {
  position: fixed;
  top: 10px;
  left: 270px;
  border-radius: 50px;
  padding: 5px;
  background-color: #232f3e;
  cursor: pointer;
}
