.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-top: 0.5rem;
}

.main {
  display: flex;
  flex-direction: column;
  width: 1000px;
}

.grid {
  display: grid;
  padding: 1rem 0;
  grid-template-columns: repeat(auto-fill, 320px);
  gap: 1rem;
}

.item {
  display: flex;
  max-width: 320px;
  min-height: 9vh;
  border: var(--border);
  border-radius: 8px;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  transition: all 150ms ease-in-out;
}

.item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-color: rgb(206, 206, 206);
}

.item > img {
  width: 66px;
  height: 66px;
  object-fit: contain;
  border-radius: 6px;
  margin-right: 1rem;
}

.item__content {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1000px) {
  .main {
    width: 95%;
    justify-content: center;
  }

  .grid {
    grid-template-columns: repeat(auto-fill, 250px);
  }

  .item {
    max-width: 250px;
  }
}
