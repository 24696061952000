.main {
  transition: opacity 0.1s ease-in-out;
}

/* .fade {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
} */
