.container{
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: rgba(223, 223, 223, 0.158); */
}

.main{
    width: 90%;
    max-width: 1000px;
    margin-top: 4rem;
}

.tabs{
    margin-top: 1rem;
}