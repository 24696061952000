.saveditem__container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.025);
  /* background-color: rgba(137, 43, 226, 0.158); */
  border: 1px solid gray;
  padding: 12px;
  width: 100%;
  max-width: 220px;
  height: 450px;
  margin-bottom: 25px;
}

.saveditem__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 220px;
  max-height: 220px;
  /* background-color: rgba(226, 153, 43, 0.13); */
}

.saveditem__image > img {
  max-width: 220px;
  max-height: 220px;
}

.saveditem__image:hover {
  cursor: pointer;
}

.saveditem__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 160px;
  padding-bottom: 8px;
}

.block-with-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.saveditem__button {
  width: 100%;
  font-size: 0.8rem;
}
