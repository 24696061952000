.horizontal__container {
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: center;
  background-color: rgba(0,0,0,.33);
  backdrop-filter: blur(20px);
  width: 95%;
  padding-top: 10px;
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-radius: 0px;
}

.horizontal__container {
  font-size: 10px;
}

.horizontal__main {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.horizontal__grid {
  display: grid;
  gap: 20px;
  align-self: center;
  grid-auto-flow: column;
  padding: 10px;
  width: 95%;
  height: 95%;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 800px) {
  .horizontal__grid {
    gap: 5px;
    grid-template-columns: repeat(auto-fit, 120px);
  }
}
