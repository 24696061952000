.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.main {
  display: flex;
  justify-content: center;
  width: 95%;
  height: 100%;
  /* background-color: rgba(255, 192, 203, 0.349); */
  padding: 3rem 0;
}

.left {
  max-width: 625px;
  min-width: 550px;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 0.6;
  padding: 1.5rem;
  background-color: rgba(0, 0, 0, 0.16);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: var(--border);
  border-radius: 8px;
  margin-right: 30px;
  overflow: hidden;
  position: relative;
  transition: height 600ms ease;
}

.form {
  display: flex;
  flex-direction: column;
}

.form > * + * {
  margin: 15px 0px 0px 0px;
}

.left > * + * {
  margin: 15px 0px 0px 0px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 28px;
}

.return {
  display: flex;
  align-items: center;
  word-wrap: break-word;
}

.right {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 90%;
  flex: 0.4;
  /* background-color: rgba(0, 0, 255, 0.747); */
  border-left: var(--border);
  padding-left: 30px;
}

.credit__cards {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -29px;
  margin-bottom: -10px;
}

.disabled__credit__card {
  filter: brightness(0.4);
}

@media only screen and (max-width: 969px) {
  .main {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .left {
    min-width: 200px;
    width: 80%;
    margin-right: 0px;
  }

  .row {
    flex-direction: column;
  }

  .row > div + div {
    margin: 15px 0px 0px 0px;
  }

  .right {
    min-width: 200px;
    margin-bottom: 1.5rem;
    max-width: 625px;
    width: 90%;
    border: none;
    padding-left: 0;
  }

  .credit__cards {
    margin-top: 0;
  }
}
