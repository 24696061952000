.pricefilter__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: brown; */
}

.pricefilter__inputs{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 8px;
}