.navbar__main {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.116); */
  background-color: var(--myDarkBlue);
  color: white;
  max-width: 100vw;
  /* min-width: 1150px; */
  height: 7vh;
  min-height: 55px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  transition: height 0.3s ease-in-out;
}

.open {
  height: 22vh;
  justify-content: flex-start;
}

.mobile {
  flex-direction: column;
  padding: 0;
  padding-top: 8px;
}

.navbar__search {
  /* background-color: rgba(255, 0, 0, 0.26); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.navbar__cart {
  /* background-color: black; */
  margin-top: 2px;
  margin-bottom: 1px;
  padding: 7px 5px;
  display: flex;
  align-items: center;
}

.navbar__cart:hover {
  padding-left: 4px;
  padding-right: 4px;
  border: 1px solid white;
  cursor: pointer;
}

.navbar__cart__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navbar__mobile {
  /* background-color: brown; */
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  /* height: 63px; */
}

.navbar__content {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 95%;
  color: hsla(0, 0%, 100%, 0.5);
  padding-top: 10px;
  padding-bottom: 20px;
  transition: height 0.3s ease-in-out;
  /* overflow: hidden; */
}

.visible {
  opacity: 1;
}

.invisible {
  opacity: 0;
}

.navbar__cart-mobile {
  margin: 0px 10px;
}

.navbar__auth {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 225px;
  height: 100%;
}

@media (hover: none) {
  .navbar__cart-mobile:active {
    transform: scale(0.8);
  }
}

@media (hover: hover) {
  .navbar__cart-mobile:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}

.bump {
  animation: bump 300ms ease-out;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

/* @media only screen and (max-width: 900px) {

  } */
