.item__main {
    display: flex;
    /* background-color: red; */
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid gray;
    padding: 20px 5px;
  }

.item__details{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background-color: rebeccapurple; */
    width: 80%;
}

.item__price{
    display: flex;
    /* background-color: cornflowerblue; */
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    /* height: 25px; */
    min-width: 140px;
    width: 35%;
}

.quantity__buttons{
    display: flex;
    justify-content: space-between;
    min-width: 65px;
    margin-top: -2px;
    /* margin-left: 15px; */
}

.quantity__buttons:hover{
    cursor: pointer;
}

/* @media only screen and (min-width: 550px) {

    .item__price{
        width: 40%;
    }

} */