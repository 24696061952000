.container {
  border-radius: 8px;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.16);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: var(--border);
  margin-bottom: 0.5rem;
}

.summary {
  display: flex;
  flex-direction: column;
  max-height: 30vh;
  overflow-y: scroll;
  padding-right: 8px;
  padding-top: 3px;
  width: 100%;
}

.item {
  display: flex;
  width: 100%;
  padding: 0.35rem 0;
}

.item__img__container {
  display: flex;
  align-items: center;
  flex: 0.13;
}
.item__img {
}

.item__img > img {
  border: var(--border);
  border-radius: 8px;
  width: 3rem;
  height: 3rem;
  padding: 5px 3px;
  object-fit: contain;
}

.item__details {
  display: flex;
  flex-direction: column;
  flex: 0.7;
  font-size: .8rem;
  margin-left: 0.75rem;
}
.item__details > * + * {
  margin-top: 0.25rem;
}

.item__price {
  display: flex;
  justify-content: flex-end;
  flex: 0.17;
  margin-right: 2px;
}

.total {
  border-top: var(--border);
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  margin-top: 1rem;
}

.row {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  margin-bottom: 0.7rem;
}

.row__total {
  font-size: 1.35rem;
  display: flex;
  justify-content: space-between;
  border-top: var(--border);
  padding-top: 1rem;
}
