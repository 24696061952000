.content {
  margin: 5px;
}

.content > * + * {
  margin-top: 25px;
}

.price-text {
  color: var(--priceColor);
  font-size: 21px;
}
