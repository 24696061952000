.home__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  /* max-width: 1500px; */
  /* min-width: 1150px; */
  padding-bottom: 10%;
  min-height: 100vh;
}

.home__image {
  width: 100%;
  z-index: -1;
  margin-bottom: -21vw;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.home__grid {
  display: grid;
  width: 95%;
  gap: 20px;
  padding: 20px 0px;
  justify-content: space-evenly;
  grid-template-columns: repeat(auto-fill, minmax(250px, 350px));
}

@media only screen and (max-width: 800px) {
  .home__container {
    align-items: center;
  }

  .home__grid {
    width: 95%;
    gap: 10px;
    justify-content: space-evenly;
    grid-template-columns: repeat(auto-fill, min(180px, 40vw));
  }
}
