.link__text {
  /* color: #0066C0; */
  color: #42a6ff;
}

.link__text__white {
  color: white;
}

.link__text__gray {
  color: gray;
}

.link__text__lightGray {
  color: rgb(212, 212, 212);
}

.link__text__orange {
  color: var(--myOrange);
}
@media (hover: none) {
  .link__text:active,
  .link__text__white:active,
  .link__text__gray:active,
  .link__text__lightGray:active {
    text-decoration: underline;
    cursor: pointer;
    color: var(--lighterOrange);
  }

  .link__text__orange:active {
    text-decoration: underline;
    cursor: pointer;
    color: white;
  }
}

@media (hover: hover) {
  .link__text:hover,
  .link__text__white:hover,
  .link__text__gray:hover,
  .link__text__lightGray:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--lighterOrange);
  }

  .link__text__orange:hover {
    text-decoration: underline;
    cursor: pointer;
    color: white;
  }
}
