.rating__filter{
    display: flex;
    flex-direction: column;
}

.rating__text{
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 100%;
    margin: 2px 0px 2px 2px;

}

.rating__text:hover{
    color: var(--myOrange);
    cursor: pointer;
}

.filter__disabled{
    color: darkgray;
}

.filter__selected{
    font-weight: bold;
}