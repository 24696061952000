.product__main {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.product__container {
  display: flex;
  justify-content: space-around;
  width: 90%;
  height: 100%;
  margin-top: 4vh;
  padding-bottom: 10vh;
}

.department-link {
  color: "white";
}

.department-link:hover {
  text-decoration: underline;
  color: "white";
  cursor: pointer;
}

.product__left {
  flex: 0.35;
  display: flex;
  flex-direction: column;
  max-width: 90%;
}

.product__images {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
}

.product__images > img {
  object-fit: contain;
  min-height: 30vh;
  max-height: 25vw;
}

.product__info {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #d5d9d9;
  padding-left: 2vw;
  padding-right: 1vw;
  margin-top: 30px;
}

.product__info > * + * {
  margin-top: 10px;
}

.price-text {
  color: var(--priceColor);
  font-size: 21px;
}

.product__description {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.product__description > ul > li {
  margin-bottom: 5px;
}

.product__right {
  flex: 0.3;
  max-width: 250px;
  margin-top: 30px;
}

@media only screen and (max-width: 1000px) {
  .product__container {
    flex-direction: column;
    align-items: center;
  }
  .product__right {
    max-width: 95%;
    width: 95%;
  }
  .product__info {
    flex: none;
    padding-left: 5vw;
    border-left: none;
    /* background-color: blue; */
    width: 95%;
  }

  .product__images > img {
    min-height: 45vh;
  }
}
