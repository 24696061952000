.saved__container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 75px;
  margin-bottom: 8px;
  background-color: var(--cardBackgroundColor);
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.saved__content {
  padding: 20px 15px 15px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.divider {
  border-bottom: 1px solid gray;
  margin: 15px 0px 20px 0px;
}

.saved__items {
  display: grid;
  gap: 20px;
  padding: 20px 0px;
  justify-content: space-evenly;
  grid-template-columns: repeat(auto-fill, 250px);
  height: 100%;
  width: 100%;
}

.saveditem-animation-enter {
  opacity: 0;
}
.saveditem-animation-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}
.saveditem-animation-exit {
  opacity: 1;
}
.saveditem-animation-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}
