body {
  background-color: rgba(23, 26, 31, 0.92);
}
.signup__container {
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  height: 100%;
}

.signup__card {
  display: flex;
  width: 90%;
  max-width: 450px;
  height: 100%;
  min-width: 300px;
}

.signup__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 10px 16px 0px 16px;
  /* background-color: blue; */
}

.signup__header{
  display: flex;
  font-size: min(calc(.25rem + 2.5vw), 1rem);
}

.signup__input {
  width: 100%;
}

.signup__input > * {
  margin-top: 1rem;
}
