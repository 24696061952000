.cart_item_main {
  padding: 15px 0px 5px 0px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid gray;
  /* background-color: teal; */
}

.cart_item_left {
  /* background-color: tomato; */
  margin-left: 5px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
}

.cart_item_left:hover {
  cursor: pointer;
}

.cart_item_right {
  display: flex;
  width: 100%;
  padding: 5px 5px 5px 10px;
  justify-content: space-between;
  align-items: flex-start;
  /* background-color: purple; */
}

.cart_item_links {
  display: flex;
  /* width: 100%; */
  max-width: 220px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.cart_item_links > * + span {
  margin-left: 5px;
}

.horizontal > img {
  width: 20vw;
  max-width: 180px;
}

.vertical > img {
  height: 20vw;
  max-height: 180px;
}

@media only screen and (max-width: 800px) {
  .cart_item_left {
    width: 130px;
  }

  .cart_item_links {
    justify-content: flex-start;
  }

  .horizontal > img {
    max-width: 130px;
  }

  .vertical > img {
    max-height: 130px;
  }
}

@media only screen and (max-width: 500px) {
  .cart_item_links {
    justify-content: flex-start;
    padding-bottom: 5px;
  }
}
