.main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 0.2rem;
  margin: 0 0.3rem;
  transition: all 0.2s ease-in-out;
}

.arrow:hover {
  cursor: pointer;
  background-color: rgba(255, 183, 49, 0.6);
  background-color: var(--myOrange);
  color: #171a1f;
  border-radius: 50%;
}

.asc {
  transform: rotate(0deg);
}

.desc {
  transform: rotate(180deg);
}

.text {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 35px;
  height: 100%;
}

.text:hover {
  cursor: pointer;
  text-decoration: underline;
  color: var(--myOrange);
}
