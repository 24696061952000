.results__container {
  /* background-color: blue; */
  background-color: #171a1f;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
  /* min-width: 1150px; */
}

.results__header {
  /* background-color: teal; */
  display: flex;
  align-items: center;
  border-bottom: 1px solid gray;
  min-height: 38px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 10px 5px 15px;
}

.results__body {
  /* background-color: pink; */
  display: flex;
  width: 75%;
  padding: 0% 35px;
  height: 100%;
  min-width: 880px;
}

.results__left {
  flex: 0.25;
  min-width: 275px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-right: 1px solid gray;
  margin-top: 15px;
  min-height: 83vh;
  /* height: 100%; */
}

.filter__container{
  background-color: var(--cardBackgroundColor);
  display: flex;
  flex-direction: column;
  height: 83vh;
  margin: 0px 20px;
  padding: 20px 20px;
  border: var(--border);
  border-radius: 15px;
}

.filter__container > * + *{
  margin-top: 35px;
}

.results__right {
  /* background-color: violet; */
  flex: 0.75;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding-bottom: 1rem;
}

.mobile__filter{
  position: fixed;
  bottom: 0px;
  left: 0px;
  margin-bottom: 10px;
  margin-left: 10px;
}

@media only screen and (max-width: 990px) {
  .results__body{
    min-width: 0;
    padding: 0;
    width: 90%;
    margin-top: 10px;
  }
  .results__right{
    flex: 1;
    margin-left: 0;
  }
}