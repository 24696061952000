.main {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  border-bottom: 1px solid gray;
  padding-bottom: 15px;
  margin-top: 20px;
}

.table {
  width: auto;
  border-spacing: 1em;
  border-collapse: collapse;
}

td {
  padding: 5px;
}
