:root {
  --img_dim: min(100px, 20vw);
}

.order {
  border: var(--border);
  border-radius: 8px;
  margin: 1rem 0;
}

.header {
  /* background-color: var(--lighterOrange); */
  background-color: rgb(66 72 80);
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1rem;
}

.header_left {
  display: flex;
}

.header_left > * + * {
  margin-left: 1rem;
}

.header_column {
  display: flex;
  flex-direction: column;
}

.column_title {
  font-size: 12px;
}

.column_content {
  font-size: 16px;
}

.header_right {
  display: flex;
  padding: 0px 8px;
}

.details {
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.027);
}

.details > * + * {
  margin-top: 1.5rem;
}

.item {
  display: flex;
  justify-content: space-between;
}

.item_left {
  display: flex;
}

.item_left_img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--img_dim);
  height: var(--img_dim);
  margin-right: 1rem;
}

.item_left_img > img {
  height: var(--img_dim);
  width: var(--img_dim);
  object-fit: contain;
}
.item_right {
  display: flex;
}

.just_padding {
  padding: 0.5rem;
  padding-top: 0.25rem;
  font-size: min(1rem, 4vw);
}

@media only screen and (max-width: 736px) {
  .header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .header_right {
    padding: 8px 0;
  }
  .item_left {
    align-items: center;
  }

  .item_left_img {
    margin-right: 0.5rem;
  }
}
