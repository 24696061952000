.item__actions {
  display: flex;
  justify-content: space-between;
  min-width: 65px;
  margin-top: -2px;
  /* margin-left: 15px; */
}

@media only screen and (max-width: 800px) {
  .item__actions {
    min-width: 43px;
    font-size: 12px;
  }
}

/* .actions__button{
    display: flex;
    background-color: orange;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 3px 5px;
}


@media (hover: none) {
    .actions__button:active{
        transition: transform .1s ease-in-out;
        transform: scale(0.8);
    }
}

@media (hover: hover) {
    .actions__button:hover{
        transition: transform .1s ease-in-out;
        transform: scale(1.1);
        background-color: rgb(255,190,105);
    }
    .actions__button:active{
        transition: transform .1s ease-in-out;
        transform: scale(0.8);
    }
} */
