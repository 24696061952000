.searchitem__container {
  background-color: var(--cardBackgroundColor);
  border: var(--border);
  display: flex;
  border-radius: 15px;
  min-height: 225px;
  box-shadow: rgba(0, 0, 0, 0.2) 5px 15px 15px;
  transition: 0.2s ease-in-out;
}

.searchitem__container:hover{
  border: var(--borderOrange);
}

.divider {
  border-bottom: 1px solid gray;
  height: 5px;
  width: 100%;
}

.itemimage__container {
  display: flex;
  height: 100%;
  align-items: center;
}

.itemimage__container > a > img {
  /* background: brown; */
  width: 22vw;
  max-width: 180px;
  max-height: 180px;
  padding: 15px 50px 15px 25px;
  object-fit: contain;
  cursor: pointer;
}

.searchitem__details {
  /* background-color: brown; */
  display: flex;
  flex-direction: column;
  padding: 25px 0px;
  margin-right: 15px;
  width: 100%;
}

.searchitem__details > a > h3{
  font-size: 1em;
}

@media only screen and (max-width: 800px) {

  .itemimage__container > img {
    padding: 15px 25px 15px 15px;
  }

}