.cart__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: darkgray;
  border-bottom: 1px solid gray;
}

.cart__items {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* justify-content: center; */
  margin-bottom: 25px;
  padding-right: 10px;
  max-height: 350px;
  overflow-y: scroll;
}

.cart__buttons {
  display: flex;
  justify-content: space-between;
  /* background-color: rgba(255, 0, 0, 0.151); */
  width: 100%;
}

.cart__total {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
  font-size: 18px;
}

.cart__empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
}

.buttons_mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .cart__header {
    font-size: 14px;
  }

  .cart__items {
    font-size: 12px;
    max-height: 280px;
  }

  .cart__total {
    font-size: 14px;
  }
}
