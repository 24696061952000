.container {
  display: flex;
  justify-content: center;
  /* background-color: rgba(0, 0, 255, 0.13); */
  margin-top: 2rem;
  padding: 2rem;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem calc(1rem + 2vw);
  height: auto;
  width: auto;
  max-width: 95%;
  border: var(--border);
  background-color: rgba(0, 0, 0, 0.16);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  width: 100%;
}

.header > * + * {
  margin-left: 0.5rem;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* min-height: 12rem; */
  height: auto;
  width: 100%;
}

.text {
  margin: 1.25rem 0;
  text-align: justify;
  line-height: 1.75rem;
  width: 100%;
}

.button {
  margin-top: 1rem;
}

@media screen and (max-width: 500px) {
.header{
    font-size: 3vw;
}

  .content {
    font-size: 3vw;
  }
}
